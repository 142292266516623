export function OrganizationsIcon() {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='48' height='48' rx='24' fill='#DAE4FF' />
      <path
        d='M19.5 23H16.6C16.0399 23 15.7599 23 15.546 23.109C15.3578 23.2049 15.2049 23.3578 15.109 23.546C15 23.7599 15 24.0399 15 24.6V33M28.5 23H31.4C31.9601 23 32.2401 23 32.454 23.109C32.6422 23.2049 32.7951 23.3578 32.891 23.546C33 23.7599 33 24.0399 33 24.6V33M28.5 33V18.2C28.5 17.0799 28.5 16.5198 28.282 16.092C28.0903 15.7157 27.7843 15.4097 27.408 15.218C26.9802 15 26.4201 15 25.3 15H22.7C21.5799 15 21.0198 15 20.592 15.218C20.2157 15.4097 19.9097 15.7157 19.718 16.092C19.5 16.5198 19.5 17.0799 19.5 18.2V33M34 33H14M23 19H25M23 23H25M23 27H25'
        stroke='#382AFF'
        strokeWidth='1.67'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
