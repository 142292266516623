import { Warning } from '@mui/icons-material'
import { Alert, Box, Button, CircularProgress, Typography } from '@mui/joy'
import { PropsWithChildren } from 'react'
import * as React from 'react'
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryPropsWithComponent,
  FallbackProps,
} from 'react-error-boundary'

import { logger } from '~/shared/lib/logger'

type Props = PropsWithChildren<
  Omit<ErrorBoundaryPropsWithComponent, 'FallbackComponent'>
> & {
  FallbackComponent?: React.ComponentType<FallbackProps>
}

export function ErrorBoundary(props: Props) {
  const {
    children,
    onError = errorHandler,
    FallbackComponent = ErrorFallback,
    ...restProps
  } = props
  return (
    <ReactErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={onError}
      {...restProps}
    >
      {children}
    </ReactErrorBoundary>
  )
}

function ErrorFallback({
  resetErrorBoundary = window.location.reload,
}: FallbackProps) {
  return (
    <Alert
      variant='soft'
      color='danger'
      invertedColors
      startDecorator={
        <CircularProgress size='lg' color='danger'>
          <Warning />
        </CircularProgress>
      }
      sx={{ alignItems: 'flex-start', gap: '1rem', borderRadius: '30px' }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography level='title-md'>На странице произошла ошибка</Typography>
        <Typography level='body-md'>
          Если после обновления страницы ошибка не проходит, обратитесь к
          администратору
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Button variant='solid' size='lg' onClick={resetErrorBoundary}>
            Обновить
          </Button>
        </Box>
      </Box>
    </Alert>
  )
}

function errorHandler(error: Error) {
  logger.error(error)
}
