export function DriversIcon() {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='48' height='48' rx='24' fill='#DAE4FF' />
      <path
        d='M34 33V31C34 29.1362 32.7252 27.5701 31 27.126M27.5 15.2908C28.9659 15.8841 30 17.3213 30 19C30 20.6787 28.9659 22.1159 27.5 22.7092M29 33C29 31.1362 29 30.2044 28.6955 29.4693C28.2895 28.4892 27.5108 27.7105 26.5307 27.3045C25.7956 27 24.8638 27 23 27H20C18.1362 27 17.2044 27 16.4693 27.3045C15.4892 27.7105 14.7105 28.4892 14.3045 29.4693C14 30.2044 14 31.1362 14 33M25.5 19C25.5 21.2091 23.7091 23 21.5 23C19.2909 23 17.5 21.2091 17.5 19C17.5 16.7909 19.2909 15 21.5 15C23.7091 15 25.5 16.7909 25.5 19Z'
        stroke='#382AFF'
        strokeWidth='1.67'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
