import { Link, styled } from '@mui/joy'

export const Wrapper = styled(Link)`
  border-radius: 16px;
  padding: 16px;
  background: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-decoration: none;
  min-height: 182px;

  &:hover {
    text-decoration: none;
  }
`
