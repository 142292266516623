export function InspectionsIcon() {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='48' height='48' rx='24' fill='#DAE4FF' />
      <path
        d='M14.4201 24.7132C14.2839 24.4975 14.2158 24.3897 14.1777 24.2234C14.1491 24.0985 14.1491 23.9015 14.1777 23.7766C14.2158 23.6103 14.2839 23.5025 14.4201 23.2868C15.5455 21.5048 18.8954 17 24.0004 17C29.1054 17 32.4553 21.5048 33.5807 23.2868C33.7169 23.5025 33.785 23.6103 33.8231 23.7766C33.8517 23.9015 33.8517 24.0985 33.8231 24.2234C33.785 24.3897 33.7169 24.4975 33.5807 24.7132C32.4553 26.4952 29.1054 31 24.0004 31C18.8954 31 15.5455 26.4952 14.4201 24.7132Z'
        stroke='#382AFF'
        strokeWidth='1.67'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24.0004 27C25.6573 27 27.0004 25.6569 27.0004 24C27.0004 22.3431 25.6573 21 24.0004 21C22.3435 21 21.0004 22.3431 21.0004 24C21.0004 25.6569 22.3435 27 24.0004 27Z'
        stroke='#382AFF'
        strokeWidth='1.67'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
