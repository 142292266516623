export function WorkRulesIcon() {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='48' height='48' rx='24' fill='#DAE4FF' />
      <path
        d='M26 23H20M22 27H20M28 19H20M32 18.8V29.2C32 30.8802 32 31.7202 31.673 32.362C31.3854 32.9265 30.9265 33.3854 30.362 33.673C29.7202 34 28.8802 34 27.2 34H20.8C19.1198 34 18.2798 34 17.638 33.673C17.0735 33.3854 16.6146 32.9265 16.327 32.362C16 31.7202 16 30.8802 16 29.2V18.8C16 17.1198 16 16.2798 16.327 15.638C16.6146 15.0735 17.0735 14.6146 17.638 14.327C18.2798 14 19.1198 14 20.8 14H27.2C28.8802 14 29.7202 14 30.362 14.327C30.9265 14.6146 31.3854 15.0735 31.673 15.638C32 16.2798 32 17.1198 32 18.8Z'
        stroke='#382AFF'
        strokeWidth='1.67'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
