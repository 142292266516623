export function TelematicsIcon() {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='48' height='48' rx='24' fill='#DAE4FF' />
      <path
        d='M22.5858 25.4142C21.8047 24.6332 21.8047 23.3668 22.5858 22.5858C23.3668 21.8047 24.6332 21.8047 25.4142 22.5858C26.1953 23.3668 26.1953 24.6332 25.4142 25.4142M19.7574 28.2426C17.4142 25.8995 17.4142 22.1005 19.7574 19.7574C22.1005 17.4142 25.8995 17.4142 28.2426 19.7574C30.5858 22.1005 30.5858 25.8995 28.2426 28.2426M16.9289 31.0711C13.0237 27.1658 13.0237 20.8342 16.9289 16.9289C20.8342 13.0237 27.1658 13.0237 31.0711 16.9289C34.9763 20.8342 34.9763 27.1658 31.0711 31.0711'
        stroke='#382AFF'
        strokeWidth='1.67'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
