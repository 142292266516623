export function BalanceIcon() {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='48' height='48' rx='24' fill='#DAE4FF' />
      <path
        d='M28.5 26H28.51M15 17V31C15 32.1046 15.8954 33 17 33H31C32.1046 33 33 32.1046 33 31V21C33 19.8954 32.1046 19 31 19L17 19C15.8954 19 15 18.1046 15 17ZM15 17C15 15.8954 15.8954 15 17 15H29M29 26C29 26.2761 28.7761 26.5 28.5 26.5C28.2239 26.5 28 26.2761 28 26C28 25.7239 28.2239 25.5 28.5 25.5C28.7761 25.5 29 25.7239 29 26Z'
        stroke='#382AFF'
        strokeWidth='1.67'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
