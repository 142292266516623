import { Routes, Route, Navigate } from 'react-router-dom'

import { MainLayout } from '~/widgets/layout/main'

import HomePage from './home'

export const Routing: React.FC = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path='/' element={<HomePage />} id={'home'} />
      </Route>

      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  )
}
