import { Box } from '@mui/joy'
import { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'
import { ErrorBoundary } from '~/shared/ui/ErrorBoundary'

type Props = {
  children?: ReactNode
}

export function MainLayout({ children }: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        minHeight: '100vh',
        minWidth: '1440px',
      }}
    >
      <ErrorBoundary>{children || <Outlet />}</ErrorBoundary>
    </Box>
  )
}
