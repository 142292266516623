import { Stack, Grid } from '@mui/joy'
import { Button } from '~/shared/ui/Buttons'
import { Logo } from '~/shared/ui/Logo'
import { PageContainer } from '~/shared/ui/PageContainer'
import { Card } from './Card'
import { useCards } from './hooks/useCards'

function HomePage() {
  const cards = useCards()

  return (
    <PageContainer title='Апарк'>
      <Stack
        spacing={4}
        alignItems='center'
        justifyContent='center'
        sx={{ margin: '20px 120px' }}
      >
        <Stack
          spacing={2}
          mb={'48px'}
          alignItems='center'
          sx={{ marginBottom: '48px !important' }}
        >
          <Logo />

          <Button sx={{ fontSize: '18px' }} href='https://inf.apark.pro/'>
            Узнать подробнее об Апарк
          </Button>
        </Stack>
        <Grid container spacing={2} sx={{ marginTop: '0 !important' }}>
          {cards.map((card) => (
            <Grid key={card.title} xs={3}>
              <Card {...card} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </PageContainer>
  )
}

export default HomePage
