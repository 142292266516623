export function DebtsIcon() {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='48' height='48' rx='24' fill='#DAE4FF' />
      <path
        d='M25.5295 20.3519C24.9571 20.7599 24.2566 21 23.5 21C21.567 21 20 19.433 20 17.5C20 15.567 21.567 14 23.5 14C24.753 14 25.8522 14.6584 26.4705 15.6481M18 32.0872H20.6103C20.9506 32.0872 21.2889 32.1277 21.6188 32.2086L24.3769 32.8789C24.9753 33.0247 25.5988 33.0388 26.2035 32.9214L29.253 32.3281C30.0585 32.1712 30.7996 31.7854 31.3803 31.2205L33.5379 29.1217C34.154 28.5234 34.154 27.5524 33.5379 26.9531C32.9832 26.4134 32.1047 26.3527 31.4771 26.8103L28.9626 28.6449C28.6025 28.9081 28.1643 29.0498 27.7137 29.0498H25.2855L26.8311 29.0498C27.7022 29.0498 28.4079 28.3633 28.4079 27.5159V27.2091C28.4079 26.5055 27.9156 25.892 27.2141 25.7219L24.8286 25.1417C24.4404 25.0476 24.0428 25 23.6431 25C22.6783 25 20.9319 25.7988 20.9319 25.7988L18 27.0249M32 18.5C32 20.433 30.433 22 28.5 22C26.567 22 25 20.433 25 18.5C25 16.567 26.567 15 28.5 15C30.433 15 32 16.567 32 18.5ZM14 26.6L14 32.4C14 32.9601 14 33.2401 14.109 33.454C14.2049 33.6422 14.3578 33.7951 14.546 33.891C14.7599 34 15.0399 34 15.6 34H16.4C16.9601 34 17.2401 34 17.454 33.891C17.6422 33.7951 17.7951 33.6422 17.891 33.454C18 33.2401 18 32.9601 18 32.4V26.6C18 26.0399 18 25.7599 17.891 25.546C17.7951 25.3578 17.6422 25.2049 17.454 25.109C17.2401 25 16.9601 25 16.4 25L15.6 25C15.0399 25 14.7599 25 14.546 25.109C14.3578 25.2049 14.2049 25.3578 14.109 25.546C14 25.7599 14 26.0399 14 26.6Z'
        stroke='#382AFF'
        strokeWidth='1.67'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
