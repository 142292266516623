export function TaxiparkIcon() {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='48' height='48' rx='24' fill='#DAE4FF' />
      <path
        d='M19.5 19H22.25M19.5 23H22.25M19.5 27H22.25M25.75 19H28.5M25.75 23H28.5M25.75 27H28.5M32 33V18.2C32 17.0799 32 16.5198 31.782 16.092C31.5903 15.7157 31.2843 15.4097 30.908 15.218C30.4802 15 29.9201 15 28.8 15H19.2C18.0799 15 17.5198 15 17.092 15.218C16.7157 15.4097 16.4097 15.7157 16.218 16.092C16 16.5198 16 17.0799 16 18.2V33M34 33H14'
        stroke='#382AFF'
        strokeWidth='1.67'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
