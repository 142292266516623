export function CarsIcon() {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='48' height='48' rx='24' fill='#DAE4FF' />
      <path
        d='M17 25H20M14 21L16 22L17.2706 18.1881C17.5329 17.4013 17.6641 17.0078 17.9073 16.717C18.1221 16.4601 18.3979 16.2613 18.7095 16.1388C19.0624 16 19.477 16 20.3064 16H27.6936C28.523 16 28.9376 16 29.2905 16.1388C29.6021 16.2613 29.8779 16.4601 30.0927 16.717C30.3359 17.0078 30.4671 17.4013 30.7294 18.1881L32 22L34 21M28 25H31M18.8 22H29.2C30.8802 22 31.7202 22 32.362 22.327C32.9265 22.6146 33.3854 23.0735 33.673 23.638C34 24.2798 34 25.1198 34 26.8V29.5C34 29.9647 34 30.197 33.9616 30.3902C33.8038 31.1836 33.1836 31.8038 32.3902 31.9616C32.197 32 31.9647 32 31.5 32H31C29.8954 32 29 31.1046 29 30C29 29.7239 28.7761 29.5 28.5 29.5H19.5C19.2239 29.5 19 29.7239 19 30C19 31.1046 18.1046 32 17 32H16.5C16.0353 32 15.803 32 15.6098 31.9616C14.8164 31.8038 14.1962 31.1836 14.0384 30.3902C14 30.197 14 29.9647 14 29.5V26.8C14 25.1198 14 24.2798 14.327 23.638C14.6146 23.0735 15.0735 22.6146 15.638 22.327C16.2798 22 17.1198 22 18.8 22Z'
        stroke='#382AFF'
        strokeWidth='1.67'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
