import { Box, Typography } from '@mui/joy'
import { HomepageCardProps } from '../type'
import { Wrapper } from './styled'

export function Card({ title, description, link, icon }: HomepageCardProps) {
  return (
    <Wrapper href={link}>
      <Box sx={{ marginBottom: '28px', height: '48px' }}>{icon}</Box>
      <Typography
        sx={{ color: '#101828', marginBottom: '4px' }}
        fontWeight={600}
        fontSize={20}
      >
        {title}
      </Typography>

      <Typography
        sx={{ color: '#98A2B3', lineHeight: '20px' }}
        fontWeight={500}
        fontSize={14}
      >
        {description}
      </Typography>
    </Wrapper>
  )
}
