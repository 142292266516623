export function PenaltiesIcon() {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='48' height='48' rx='24' fill='#DAE4FF' />
      <path
        d='M20.5 23.5H26.5C28.7091 23.5 30.5 21.7091 30.5 19.5C30.5 17.2909 28.7091 15.5 26.5 15.5H20.5V23.5ZM20.5 23.5H18.5M25.5 27.5H18.5M20.5 16V32.5'
        stroke='#382AFF'
        strokeWidth='1.67'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
