import {
  CSS_DOMAIN,
  EPS_DOMAIN,
  TMS_DOMAIN,
  DDP_DOMAIN,
  TELEMATICS_DOMAIN,
} from '~/shared/config/env'
import {
  BalanceIcon,
  CarsIcon,
  DebtsIcon,
  DriversIcon,
  InspectionsIcon,
  OrganizationsIcon,
  PenaltiesIcon,
  RepairOrdersIcon,
  RoadAccidentsIcon,
  TaxiparkIcon,
  TelematicsIcon,
  WorkRulesIcon,
  DashboardsIcon,
} from '~/shared/ui/icons'

import { HomepageCardProps } from '../type'

export const useCards = (): HomepageCardProps[] => {
  return [
    {
      title: 'Автомобили',
      link: `${TMS_DOMAIN}cars`,
      description: 'Управление автопарком',
      icon: <CarsIcon />,
    },
    {
      title: 'Водители',
      description: 'Проверка водителей',
      link: `${TMS_DOMAIN}drivers`,
      icon: <DriversIcon />,
    },
    {
      title: 'Баланс',
      description: 'Транзакции и обращения',
      link: `${TMS_DOMAIN}balance/balance-histories`,
      icon: <BalanceIcon />,
    },
    {
      title: 'Условия работы',
      description: 'Управление условиями работы',
      link: `${TMS_DOMAIN}work-rules`,
      icon: <WorkRulesIcon />,
    },
    {
      title: 'Таксопарк',
      description: 'Настройки таксопарка',
      link: `${TMS_DOMAIN}taxipark/information`,
      icon: <TaxiparkIcon />,
    },
    {
      title: 'Организации',
      description: 'Управление организациями',
      link: `${TMS_DOMAIN}organizations/car-rentals`,
      icon: <OrganizationsIcon />,
    },
    {
      title: 'Осмотры',
      description: 'Осмотры автомобилей',
      link: `${CSS_DOMAIN}inspections`,
      icon: <InspectionsIcon />,
    },
    {
      title: 'Штрафы',
      description: 'Работа со штрафами',
      link: `${EPS_DOMAIN}penalties`,
      icon: <PenaltiesIcon />,
    },
    {
      title: 'Задолженности',
      description: 'Работа с задолженностями',
      link: `${DDP_DOMAIN}debts`,
      icon: <DebtsIcon />,
    },
    {
      title: 'Телематика',
      description: 'Блокировка/Разблокировка автомобилей',
      link: `${TELEMATICS_DOMAIN}`,
      icon: <TelematicsIcon />,
    },
    {
      title: 'Заказ наряды',
      description: 'Работа с заказ-нарядами',
      link: `${CSS_DOMAIN}repair-orders`,
      icon: <RepairOrdersIcon />,
    },
    {
      title: 'ДТП',
      description: 'ДТП и Страховые случаи',
      link: `${CSS_DOMAIN}road-accidents`,
      icon: <RoadAccidentsIcon />,
    },
    {
      title: 'Дашборды',
      description: 'Графики и отчеты',
      link: `${TMS_DOMAIN}dashboards`,
      icon: <DashboardsIcon />,
    },
  ]
}
