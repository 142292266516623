export function DashboardsIcon() {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='48' height='48' rx='24' fill='#DAE4FF' />
      <path
        d='M33.2104 27.89C32.5742 29.3945 31.5792 30.7202 30.3123 31.7513C29.0454 32.7824 27.5452 33.4874 25.9428 33.8048C24.3405 34.1221 22.6848 34.0422 21.1205 33.5718C19.5563 33.1015 18.131 32.2551 16.9694 31.1067C15.8078 29.9582 14.9452 28.5428 14.457 26.984C13.9689 25.4252 13.87 23.7705 14.169 22.1646C14.468 20.5588 15.1559 19.0506 16.1724 17.772C17.189 16.4934 18.5033 15.4833 20.0004 14.83M33.2392 20.1732C33.6395 21.1396 33.8851 22.1614 33.9684 23.2009C33.989 23.4577 33.9993 23.5861 33.9483 23.7017C33.9057 23.7983 33.8213 23.8898 33.7284 23.9399C33.6172 24 33.4783 24 33.2004 24H24.8004C24.5204 24 24.3804 24 24.2734 23.9455C24.1793 23.8976 24.1028 23.8211 24.0549 23.727C24.0004 23.62 24.0004 23.48 24.0004 23.2V14.8C24.0004 14.5221 24.0004 14.3832 24.0605 14.272C24.1107 14.1791 24.2021 14.0947 24.2987 14.0521C24.4144 14.0011 24.5428 14.0114 24.7996 14.032C25.839 14.1153 26.8608 14.3609 27.8272 14.7612C29.0405 15.2638 30.1429 16.0004 31.0715 16.9289C32.0001 17.8575 32.7367 18.9599 33.2392 20.1732Z'
        stroke='#382AFF'
        strokeWidth='1.67'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
