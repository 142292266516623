import CssBaseline from '@mui/joy/CssBaseline'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import adapterLocale from 'date-fns/locale/ru'
import { parse, stringify } from 'query-string'
import { HelmetProvider } from 'react-helmet-async'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { Routing } from '~/pages'
import { ReloadSw } from '~/widgets/ReloadSw'
import { history } from '~/shared/lib/history'
import { GlobalStyles } from '~/shared/ui/GlobalStyles'
const queryClient = new QueryClient()

export function App() {
  return (
    <>
      <CssBaseline />
      <GlobalStyles />
      <ReloadSw />

      <HistoryRouter history={history}>
        <QueryParamProvider
          adapter={ReactRouter6Adapter}
          options={{
            searchStringToObject: parse,
            objectToSearchString: stringify,
          }}
        >
          <QueryClientProvider client={queryClient}>
            <LocalizationProvider
              adapterLocale={adapterLocale}
              dateAdapter={AdapterDateFns}
            >
              <HelmetProvider>
                <Routing />
              </HelmetProvider>
            </LocalizationProvider>
          </QueryClientProvider>
        </QueryParamProvider>
      </HistoryRouter>
    </>
  )
}
