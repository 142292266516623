import Pino from 'pino'

import { env } from '~/shared/config'

let clientLogger: Pino.Logger

function createOrInitLogger() {
  if (typeof clientLogger === 'undefined') {
    clientLogger = Pino({
      level: env.LOG_LEVEL,
    })
  }
  return clientLogger
}

export const logger = createOrInitLogger()
